function OptanonWrapper() {
	var cookieBannerContainer = document.getElementById('onetrust-consent-sdk');
	document.body.prepend(cookieBannerContainer);
	// Handle inital load
	// var cookieBannerOverlay = document.getElementsByClassName('onetrust-pc-dark-filter');
	// if (cookieBannerOverlay[0] && window.getComputedStyle(cookieBannerOverlay[0]).display === 'block') {
	// 	document.body.classList.add('ot-overflow-hidden');
	// } else {

	// 	document.body.classList.remove('ot-overflow-hidden');
	// }
	// Handle close/submit
	// document.querySelectorAll('#onetrust-accept-btn-handler,.onetrust-close-btn-handler,#accept-recommended-btn-handler,.save-preference-btn-handler, #onetrust-reject-all-handler, .ot-pc-refuse-all-handler').forEach(function(item) {
	// 	item.addEventListener('click', function() {
	// 		document.body.classList.remove('ot-overflow-hidden');
	// 	});
	// });





	function getCookie(name) {
		var value = '; ' + document.cookie;
		var parts = value.split('; ' + name + '=');
		if (parts.length == 2) {
			return parts
				.pop()
				.split(';')
				.shift();
		}
	}
	if (!getCookie('OptanonAlertBoxClosed')) {
		$('.ot-accordion-layout button').attr('aria-expanded', 'true');
		if ($('.ot-pc-footer .ot-pc-refuse-all-handler').length > 0) {
			$('.ot-pc-footer .ot-pc-refuse-all-handler').insertAfter('#accept-recommended-btn-handler');
		}
	}



	const gpcEnabled = navigator.globalPrivacyControl;
	if (gpcEnabled) {
        var cookieBanner = document.getElementById("onetrust-consent-sdk");
        cookieBanner.classList.toggle("gpc-detected");
	}

	var gpcOnController = document.querySelector(".gpc-on"),
		gpcOKButton = document.getElementById("cookieGPCControlButton"),
		buttonContainer = document.querySelector(".banner-actions-container")

	if (gpcOnController) {
		buttonContainer.appendChild(gpcOKButton);

		if (gpcEnabled) {
			gpcOKButton.addEventListener('click', function() {
				OneTrust.Close();
				document.body.classList.remove('ot-overflow-hidden');
			});
		}
		else {
			gpcOKButton.addEventListener('click', function() {
				OneTrust.AllowAll();
				document.body.classList.remove('ot-overflow-hidden');
			});
		}
	}

}
